(function($, window, undefined){

  var $coverr = $('.coverr');
  var $video = $coverr.find('video');
  var $title = $coverr.find('.title-container');
  var $headlines = $title.find('.headlines');
  var $subHeadlines = $headlines.find('.sub-headlines');
  var $w = $(window);

  // if (Modernizr && Modernizr.touch) {
  //   var newVideoHtml = $video.parent().html();
  //   $parent = $video.parent().html(newVideoHtml);
  //   $video = $parent.find('video');
  //   setTimeout(function(){
  //     var video = $parent.find('video').get(0);
  //     if (video && !video.paused) {
  //       $coverr.find('.poster').hide();
  //       $video.show();
  //     }
  //   }, 100);
  // }

  // Resize video so it's full height/width
  function resizeVideo() {
    var wh = $w.height();
    var ww = $w.width();
    var windowRatio = wh / ww;

    $coverr.css({
      height: wh,
      width: ww
    });

    var vh = $video.height(wh);
    var vw = $video.width();
    if (ww > $video.width()) {
      var percentWidth = ww / vw;
      var videoHeight = wh * percentWidth;
      $video.height(videoHeight);
    }

    var headlinesHeight = $headlines.height();
    var headlinesPaddingTop = ($title.height() - headlinesHeight) / 2;
    var subHeadlinesHeight = $subHeadlines.height();
    if (headlinesPaddingTop / subHeadlinesHeight < 0.9) {
      headlinesPaddingTop -= headlinesPaddingTop / 3;
    }
    $headlines.css({
      'padding-top': headlinesPaddingTop,
    });
  }

  // Throttle the resize video function
  var resizeThrottle;
  function onWindowResize() {
    clearTimeout(resizeThrottle);
    resizeThrottle = setTimeout(resizeVideo, 50);
  }

  // resize video on throttled window resize
  $w.on('resize', onWindowResize).trigger('resize');

  // Resize and show on video load
  $video.on('loadeddata', function() {
    resizeVideo();
    setTimeout(function(){
      $('main.fade').addClass('in');
    }, 0);
  });

  // Failsafe fade in everything
  setTimeout(function(){
    $('main.fade').addClass('in');
  }, 500);

  // Learn more link
  var $learnBtn = $subHeadlines.find('.btn');
  $learnBtn.on('click', function(e){
    e.preventDefault();

    $('body,html').animate({
      scrollTop: $('section').first().position().top - $('nav.navbar').outerHeight()
    }, 1000);
    return false;
  });

})(jQuery, this);