(function($, window, undefined){

  var $w = $(window);
  var $nav = $('nav.navbar');
  var $body = $('body');
  var $page = $body.add($('html'));
  var scrollThrottle;
  var resizeThrottle;
  var $testimonialLinks = $('a.testimonial');
  var $testimonials = $('div.testimonial');

  function toggleNavbar() {
    var method = ($w.scrollTop() > 45) ? 'addClass' : 'removeClass';
    $nav[method]('on');
  }

  // function onWindowScroll() {
  //   clearTimeout(scrollThrottle);
  //   scrollThrottle = setTimeout(toggleNavbar, 50);
  // }

  function onWindowResize() {
    clearTimeout(resizeThrottle);
    resizeThrottle = setTimeout(function(){
      closeMenu();
    }, 50);
  }

  function openMenu(e) {
    if (e) e.preventDefault();
    $page.css({
      width: $body.width(),
      overflowX: 'hidden',
    });
    $body.addClass('menu-open');
    return false;
  }

  function closeMenu(e) {
    if (e) e.preventDefault();
    $page.css({
      width: '',
      overflowX: '',
    });
    $('body').removeClass('menu-open');
    return false;
  }

  function scrollPage(scrollTop, hash) {
    $page.animate({
      scrollTop: scrollTop || 0
    }, 1000, function() {
      if (hash != undefined) {
        window.location.hash = hash;
      } else {
        if (history && typeof history.pushState != undefined) {
          history.pushState("", document.title, window.location.pathname + window.location.search);
        }
      }
    });
  }

  function toggleTestimonials(e) {
    if (e) e.preventDefault();
    var isHidden = $('.testimonial-list [hidden]').size()
    if (isHidden) {
      $('.testimonial-list [hidden]').removeAttr('hidden').addClass('was-hidden');
      $('.js-show-testimonials').html('Hide all testimonials &raquo;');
    } else {
      $('.testimonial-list .was-hidden').attr('hidden', true).removeClass('was-hidden');
      $('.js-show-testimonials').html('View full testimonials &raquo;');
    }
    return false;
  }


  // Animate navbar
  $w.on('scroll', toggleNavbar).trigger('scroll');

  // Close menu on resize
  $w.on('resize', onWindowResize).trigger('resize');

  // Set up menu actions
  $('.js-open-menu').on('click', openMenu);
  $('.js-close-menu').on('click', closeMenu);


  // Testim
  $testimonialLinks.each(function(i, el) {
    var $el = $(el)
    var popover = $el.popover({
      container: '#testimonials',
      html: true,
      trigger: 'manual',
      animation: false,
      // trigger: 'hover focus',
      // trigger: 'click',
      template: '<div class="popover testimonial-popover" role="tooltip"><div class="popover-arrow"></div><div class="popover-content"></div></div>',
    });

    $el.on('click', function(e) {
      e.preventDefault();
      return false;
    });

    var outTimeout;
    var popoverOver = false;
    $el.on('mouseenter', function(){
      $testimonialLinks.not($el).popover('hide');
      $el.popover('show').addClass('active');
    });

    $el.on('mouseleave', function(){
      outTimeout = setTimeout(function(){
        $el.popover('hide').removeClass('active');
      }, 100);
    });

    $el.on('shown.bs.popover', function(){
      $('.testimonial-popover').on('mouseenter', function(){
        clearTimeout(outTimeout);
      }).on('mouseleave', function(){
        outTimeout = setTimeout(function(){
          $el.popover('hide').removeClass('active');
        }, 300);
      }).find('a').each(function(i, el){
        var $link = $(el);
        var selector = $link.attr('href');
        var $target = $(selector);
        if ($target.size()) {
          $link.on('click', function(e){
            toggleTestimonials(e);
            var scrollTop = $target.position().top - $nav.outerHeight();
            scrollPage(scrollTop, selector);
          });
        }
      });
    });
  });

  $('.js-show-testimonials').on('click', function(e) {
    toggleTestimonials(e);
    setTimeout(function(){
      var $el = $('.testimonial-list [hidden]').size() ? $('#testimonials') : $testimonials.first()
      var scrollTop = $el.position().top - $nav.outerHeight();
      scrollPage(scrollTop, $el.attr('id'));
    }, 100);
  });

  $testimonials.each(function(i, el) {
    var $el = $(el);
    var selector = '#' + $el.attr('id');
    var $target = $(selector);
    if ($target.size()) {
      if (window.location.hash === selector) {
        setTimeout(function(){
          toggleTestimonials();
          var scrollTop = $target.position().top  - $nav.outerHeight();
          scrollPage(scrollTop, selector);
        }, 500);
      }
    }
  });

  $('.js-in-page-link').each(function(i, el){
    var $el = $(el);
    var href = $el.attr('href');
    var $target = $(href);
    if ($target.size()) {
      $el.on('click', function(e){
        e.preventDefault();
        var scrollTop = $target.position().top  - $nav.outerHeight();
        scrollPage(scrollTop, href);
        return false;
      });
    }
  })

  $('#menu .link').each(function(i, el){
    var $link = $(el);
    var href = $link.attr('href');
    var $target = $(href);

    $link.on('click', function(e){
      closeMenu(e);
      if ($target.size()) {
        var scrollTop = $target.position().top - $nav.outerHeight();
        scrollPage(scrollTop, href)
      } else {
        scrollPage();
      }
      return false;
    });

    if (window.location.hash === href) {
      setTimeout(function(){
        $link.trigger('click');
      }, 500);
    }
  });

})(jQuery, this);
